<script setup lang="ts">
import type { CmsElementZebraCmsHeroSlider } from "~/composables/useCustom";

const props = defineProps<{
  content: CmsElementZebraCmsHeroSlider;
}>();

import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css'

const settings = {
  itemsToShow: 1,
  wrapAround: true,
  snapAlign: 'start',
  autoplay: 6000,
  transition: 600,
  pauseAutoplayOnHover: true
}

const heroSlider = ref(null)
const currentSlide = ref(0)

const slideTo = (val) => {
  currentSlide.value = val;
};

const { Slides } = props.content?.config

</script>
<template>
  <div class="hero-section relative">
    <div class="hero-slider m-0">
      <button class="slick-prev slick-arrow" aria-label="Prev" @click="heroSlider.prev()">Prev</button>

      <Carousel v-if="Slides.value && Slides.value.length > 1" v-bind="settings" ref="heroSlider" v-model="currentSlide">
        <template v-for="slide in Slides.value" :key="slide">
        <Slide>
          <div class="hero-items relative w-full">
            <figure class="hero-overlay">
              <template v-if="slide.sliderBackgroundDesktop && slide.sliderBackgroundMobile">
                <NuxtImg format="webp" :src="slide.sliderBackgroundDesktop.url" class="img-cover hidden md:block" alt="" width="1680"  densities="x1" />
                <NuxtImg format="webp" :src="slide.sliderBackgroundMobile.url" class="img-cover md:hidden" alt="" width="768" densities="x1" />
              </template>
              <template v-else-if="slide.sliderBackgroundDesktop">
                <NuxtImg format="webp" :src="slide.sliderBackgroundDesktop.url" class="img-cover" alt="" width="1680" densities="x1" />
              </template>
              <template v-else-if="slide.sliderBackgroundMobile">
                <NuxtImg format="webp" :src="slide.sliderBackgroundMobile.url" class="img-cover" alt="" width="768" densities="x1" />
              </template>
            </figure>
            <div class="w-full absolute bottom-[120px] md:top-2/4 md:-translate-y-2/4 text-white">
              <div class="container container_sm">
                <div class="overlayTxtSize">
                  <h2 class="text-[40px] md:text-5xl lg:text-6xl mb-5 font-bold">{{slide.sliderHeadlineText}}</h2>
                  <div class="mb-7 md:mb-8" v-html="slide.sliderMainText"></div>
                  <SharedButton btnType="primary" :button="slide.button"></SharedButton>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        </template>
      </Carousel>

      <div v-if="Slides.value && Slides.value[0] && Slides.value.length == 1" class="hero-items relative w-full">
        <figure class="hero-overlay">
          <template v-if="Slides.value[0].sliderBackgroundDesktop && Slides.value[0].sliderBackgroundMobile">
            <NuxtImg format="webp" :src="Slides.value[0].sliderBackgroundDesktop.url" class="img-cover hidden md:block" alt="" width="1680"  densities="x1" />
            <NuxtImg format="webp" :src="Slides.value[0].sliderBackgroundMobile.url" class="img-cover md:hidden" alt="" width="768" densities="x1" />
          </template>
          <template v-else-if="Slides.value[0].sliderBackgroundDesktop">
            <NuxtImg format="webp" :src="Slides.value[0].sliderBackgroundDesktop.url" class="img-cover" alt="" width="1680" densities="x1" />
          </template>
          <template v-else-if="Slides.value[0].sliderBackgroundMobile">
            <NuxtImg format="webp" :src="Slides.value[0].sliderBackgroundMobile.url" class="img-cover" alt="" width="768" densities="x1" />
          </template>
        </figure>
        <div class="w-full absolute bottom-[120px] md:top-2/4 md:-translate-y-2/4 text-white">
          <div class="container container_sm">
            <div class="overlayTxtSize">
              <h2 class="text-[40px] md:text-5xl lg:text-6xl mb-5 font-bold">{{Slides.value[0].sliderHeadlineText}}</h2>
              <div class="mb-7 md:mb-8" v-html="Slides.value[0].sliderMainText"></div>
              <SharedButton btnType="primary" :button="Slides.value[0].button"></SharedButton>
            </div>
          </div>
        </div>
      </div>

      <ul class="slick-dots" role="tablist" style="" v-if="Slides.value && Slides.value[0] && Slides.value.length > 1">
        <template v-for="(slide, index) in Slides.value">
          <li :class="{'slick-active': currentSlide == index}" role="presentation" @click="slideTo(index)">&nbsp;</li>
        </template>
      </ul>

      <button class="slick-next slick-arrow" aria-label="Next" @click="heroSlider.next()">Next</button>
    </div>
  </div>
</template>
